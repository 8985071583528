import React from 'react'
import * as PropTypes from 'prop-types'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { Link, graphql } from 'gatsby'
import styled from '@emotion/styled'
import { Styled } from 'theme-ui'

import Layout from '../components/layout'
import Image from '../components/image'
import SEO from '../components/seo'
import { createPath } from '../helpers'

const BlogPostWrapper = styled.div`
  position: relative;

  & + & {
    margin-top: ${({ theme }) => theme.spacing.s4}px;
  }
`

const TeaserImageWrapper = styled.div`
  max-height: 30vh;
  overflow: hidden;

  & img,
  svg {
    max-height: 30vh;
    display: block;
  }
`

const BlogPostTitle = styled(Styled.h1)`
  margin: ${({ theme }) => theme.spacing.s2}px 0 0;
`

const BlogPostMeta = styled.div`
  margin: ${({ theme }) => theme.spacing.s1}px 0;
  opacity: 0.8;
`

const BlogPost = function({ blogPost }) {
  const {
    title,
    teaserImage,
    teaser: { childMdx: teaser },
    content: { childMdx: content },
    publishDate,
    slug,
  } = blogPost

  const path = createPath({ slug, prefix: 'blog' })

  return (
    <BlogPostWrapper>
      {teaserImage && (
        <Link to={path}>
          <TeaserImageWrapper>
            <Image
              {...teaserImage}
              alt={title}
              fluid={{
                ...teaserImage.fluid,
                base64: teaserImage.sqip
                  ? teaserImage.sqip.dataURI
                  : teaserImage.fluid.base64,
              }}
            />
          </TeaserImageWrapper>
        </Link>
      )}

      <BlogPostTitle>
        <Link to={path}>{title}</Link>
      </BlogPostTitle>
      <BlogPostMeta>
        {content.wordCount.words} words • {content.timeToRead} min read time •{' '}
        {publishDate}
      </BlogPostMeta>

      <MDXProvider>
        <MDXRenderer>{teaser.body}</MDXRenderer>
      </MDXProvider>

      <Link className="more" to={path} aria-label={`Learn more about ${title}`}>
        More
      </Link>
    </BlogPostWrapper>
  )
}

BlogPost.propTypes = {
  blogPost: PropTypes.object.isRequired,
}

const IndexPage = ({ data }) => {
  const blogPosts = data.allContentfulBlogPost.edges.map(({ node }) => (
    <BlogPost key={node.id} blogPost={node} />
  ))
  return (
    <Layout>
      <SEO title="Home" />
      {blogPosts}
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default IndexPage

export const pageQuery = graphql`
  query indexQuery {
    allContentfulBlogPost {
      edges {
        node {
          id
          contentful_id
          slug
          title
          publishDate(formatString: "DD.MM.YY - HH:MM")
          teaser {
            childMdx {
              body
            }
          }
          content {
            childMdx {
              timeToRead
              wordCount {
                words
              }
            }
          }
          teaserImage {
            contentful_id
            file {
              contentType
              details {
                image {
                  height
                  width
                }
              }
            }
            svgContent
            sqip(mode: 4, numberOfPrimitives: 42, blur: 0) {
              dataURI
            }
            fluid(maxWidth: 960) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`
